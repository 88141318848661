import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import get from 'lodash.get';
import { Heading } from '@devseed-ui/typography';
import { themeVal, glsp, media } from '@devseed-ui/theme-provider';

import Prose from '../styles/typography/prose';
import UniversalGridder from '../styles/universal-gridder';
import Media from './media';

export const MosaicItem = styled.li`
  grid-column-end: span 2;

  ${media.mediumUp`
    grid-column-end: span 3;
  `}

  &:nth-child(odd) {
    grid-column-start: content-start;

    ${media.mediumUp`
      grid-column-start: content-2;
    `}
  }

  &:nth-child(even) {
    grid-column-start: content-3;
    margin-top: ${glsp(4)};

    ${media.mediumUp`
      grid-column-start: content-5;
      margin-top: ${glsp(6)};
    `}

    ${media.largeUp`
      grid-column-start: content-6;
    `}
  }
`;

const MosaicNumbersSelf = styled.div``;

const MosaicFeaturesSelf = styled.div`
  ${MosaicItem} {
    grid-column-end: span 4;

    ${media.smallUp`
      grid-column-end: span 2;
    `}

    ${media.mediumUp`
      grid-column-end: span 6;
    `}

    ${media.largeUp`
      grid-column-end: span 5;
    `}

    &:nth-child(odd) {
      grid-column-start: content-start;

      ${media.smallUp`
        grid-column-start: content-start;
      `}

      ${media.mediumUp`
        grid-column-start: content-2;
      `}

      ${media.largeUp`
        grid-column-start: content-2;
      `}
    }

    &:nth-child(even) {
      grid-column-start: content-start;
      margin-top: 0;

      ${media.smallUp`
        grid-column-start: content-3;
        margin-top: ${glsp(4)};
      `}

      ${media.mediumUp`
        grid-column-start: content-2;
        margin-top: 0;
      `}

      ${media.largeUp`
        grid-column-start: content-7;
        margin-top: ${glsp(6)};
      `}
    }
  }
`;

const Tile = styled.article`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: ${glsp(1)};

  ${media.smallUp`
    grid-gap: ${glsp(1.25)};
  `}

  ${media.mediumUp`
    grid-gap: ${glsp(1.5)};
  `}
`;

export const TileTitle = styled(Heading).attrs({ as: 'h1' })`
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 0;

  ${media.mediumUp`
    font-size: 2rem;
    line-height: 2.5rem;
  `}

  strong {
    position: relative;
    display: block;
    font-size: 4rem;
    line-height: 1;
    font-weight: normal;
    white-space: nowrap;
    padding-bottom: ${glsp()};
    margin-bottom: ${glsp()};

    ${media.mediumUp`
      font-size: 7rem;
      margin-bottom: ${glsp(2)};
    `}

    sup {
      top: -0.95em;
      font-size: 50%;
      color: ${themeVal('color.primary')};
      margin-left: ${glsp(0.25)};
    }

    &::after {
      position: absolute;
      bottom: 0;
      left: 50%;
      content: '';
      width: 100%;
      height: 0.5rem;
      background: ${themeVal('type.base.color')};
      transform: translate(-50%, 0);
    }
  }
`;

const TileDescription = styled(Prose)`
  font-variation-settings: 'wdth' 64, 'wght' 356;
`;

const TileMedia = styled(Media)`
  grid-row: 1;
`;

export const TileNumber = (props) => {
  const { value, unit, title, className, children, dangerouslySetInnerHTML } =
    props;
  const html = get(dangerouslySetInnerHTML, '__html');
  const hasDescription = !!html || !!children;
  // Use html if provided, otherwise default to children.
  // HTML is needed for the rendered markdown content.
  const descriptionProps = html
    ? {
        dangerouslySetInnerHTML
      }
    : { children };
  return (
    <Tile className={className}>
      <TileTitle>
        <strong>
          {value}
          {unit && <sup>{unit}</sup>}
        </strong>
        {title}
      </TileTitle>
      {hasDescription && <TileDescription {...descriptionProps} />}
    </Tile>
  );
};

TileNumber.propTypes = {
  className: T.string,
  value: T.oneOfType([T.string, T.number]),
  unit: T.string,
  title: T.string,
  children: T.node,
  dangerouslySetInnerHTML: T.object
};

export const TileFeature = (props) => {
  const { title, media, className, children, dangerouslySetInnerHTML } = props;

  const fluid = get(media, 'url.childImageSharp.fluid');
  const src = get(media, 'src');
  const alt = get(media, 'alt');
  const attribution = get(media, 'attribution');
  const html = get(dangerouslySetInnerHTML, '__html');
  const hasDescription = !!html || !!children;
  // Use html if provided, otherwise default to children.
  // HTML is needed for the rendered markdown content.
  const descriptionProps = html
    ? {
        dangerouslySetInnerHTML
      }
    : { children };
  return (
    <Tile className={className}>
      <TileTitle>{title}</TileTitle>
      {hasDescription && <TileDescription {...descriptionProps} />}
      {(src || fluid) && (
        <TileMedia
          attribution={attribution}
          decoration='none'
          src={src}
          fluid={fluid}
          alt={alt || 'Image'}
        />
      )}
    </Tile>
  );
};

TileFeature.propTypes = {
  className: T.string,
  media: T.shape({
    src: T.string,
    fluid: T.object,
    alt: T.string
  }),
  title: T.string,
  children: T.node,
  dangerouslySetInnerHTML: T.object
};

export const MosaicFeatures = ({ className, items, listGrid }) => {
  return (
    <MosaicFeaturesSelf className={className}>
      <UniversalGridder as='ul' grid={listGrid}>
        {items.map((item, idx) => {
          // The items will never change order between renders so it's safe to use
          // the array index as the key.
          const k = `item-${idx}`;
          const { body, ...prp } = item;
          return (
            <MosaicItem key={k}>
              <TileFeature
                {...prp}
                dangerouslySetInnerHTML={{ __html: body }}
              />
            </MosaicItem>
          );
        })}
      </UniversalGridder>
    </MosaicFeaturesSelf>
  );
};

MosaicFeatures.propTypes = {
  className: T.string,
  listGrid: T.object,
  items: T.arrayOf(
    T.shape({
      src: T.string,
      fluid: T.object,
      alt: T.string,
      title: T.string,
      body: T.string
    })
  )
};

export const MosaicNumbers = ({ className, items, listGrid }) => {
  return (
    <MosaicNumbersSelf className={className}>
      <UniversalGridder as='ul' grid={listGrid}>
        {items.map((item, idx) => {
          // The items will never change order between renders so it's safe to use
          // the array index as the key.
          const k = `item-${idx}`;
          const { body, ...prp } = item;
          return (
            <MosaicItem key={k}>
              <TileNumber {...prp} dangerouslySetInnerHTML={{ __html: body }} />
            </MosaicItem>
          );
        })}
      </UniversalGridder>
    </MosaicNumbersSelf>
  );
};

MosaicNumbers.propTypes = {
  className: T.string,
  listGrid: T.object,
  items: T.arrayOf(
    T.shape({
      value: T.oneOfType([T.string, T.number]),
      unit: T.string,
      title: T.string,
      body: T.string
    })
  )
};

// The Fold Mosaic Features is a component styled for use inside a fold.
export const FoldMosaicFeatures = styled(MosaicFeatures).attrs((props) => ({
  listGrid: props.listGrid || {
    smallUp: ['content-start', 'content-end'],
    mediumUp: ['content-3', 'content-end'],
    largeUp: ['content-3', 'content-end']
  }
}))`
  grid-row: 2;
  grid-column: content-start / content-end;

  ${media.mediumUp`
    grid-column: content-3 / content-end;
  `}

  ${media.largeUp`
    grid-column: content-3 / content-end;
  `}

  ${MosaicItem} {
    grid-column-end: span 4;

    ${media.smallUp`
      grid-column-end: span 2;
    `}

    ${media.mediumUp`
      grid-column-end: span 3;
    `}

    ${media.largeUp`
      grid-column-end: span 5;
    `}

    &:nth-child(odd) {
      grid-column-start: content-start;

      ${media.smallUp`
        grid-column-start: content-start;
      `}

      ${media.mediumUp`
        grid-column-start: content-3;
      `}

      ${media.largeUp`
        grid-column-start: content-3;
      `}
    }

    &:nth-child(even) {
      grid-column-start: content-start;
      margin-top: 0;

      ${media.smallUp`
        grid-column-start: content-3;
        margin-top: ${glsp(4)};
      `}

      ${media.mediumUp`
        grid-column-start: content-6;
        margin-top: 0;
      `}

      ${media.largeUp`
        grid-column-start: content-8;
        margin-top: ${glsp(6)};
      `}
    }
  }
`;
